.home-front-wrapper{
	display: flex;
	background-color: #f5f1ed;
	width: 100vw;
	height: 44vw;
}

.home-front-image{
	width: 100%;
	height: 100%;
	position: relative;
	animation: slidein 1s cubic-bezier(.63,1.18,1,.96); 
	animation-fill-mode: forwards;
	opacity: 0;
	
	object-fit: cover;
}

.home-front-inside-wrapper{
	display: flex;
}

.home-front-display{
	width: 60vw;
	height: 43vw;
}

@keyframes slidein {
	0%{ 
		left: -60vw;
		opacity: 0;
	}
	50%{
		opacity: 0.2;
	}
	100%{ 
		left: 0vw;
		opacity: 1;
	}
}

.home-front-text{
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 50px;
	padding-right: 100px;
}

.home-front-info{
	font-family: Gill Sans W01,Arial,sans-serif;
	font-size: 10px;
	letter-spacing: .15em;
	border-bottom: 1px solid black;
	width: fit-content;
	padding-bottom: 10px;
	cursor: pointer;
}

.home-front-title{
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 22px;
	line-height: 38px;
	margin-top: 10px;
	cursor: pointer;
}

.home-front-button{
	font-family: Gill Sans W01,Arial,sans-serif;
	font-size: 11px;
	letter-spacing: .15em;
	border: 1px solid #999;
	height: 40px;
	width: fit-content;
	display: flex;
	align-items: center;
	padding: 0px 30px;
	margin-top: 40px;
	cursor: pointer;
	background-color: #f5f1ed;
	filter: invert(0%);
	transition: filter 0.5s;
}


.home-front-button:hover{
	filter: invert(100%);
}

@media only screen and (max-width: 1240px) {
  .home-front-title{
  	font-size: 24px;
  	line-height: 28px;
  }
}

@media only screen and (max-width: 1024px) {
  .home-front-wrapper{
  	flex-direction: column;
	height: auto;
  }
	.home-front-display{
		width: auto;
		height: auto;
	}
  .home-front-inside-wrapper{
	flex-direction: column;
}
  .home-front-image{
  	width: 100vw;
	height: 70vw;
  }
  .home-front-text{
  	padding-top: 50px;
  	padding-bottom: 50px;
  	padding-left: 15vw;
  	padding-right: 15vw;
  	align-items: center;	
  }
  .home-front-info{
  	font-size: 10px;
  }
  .home-front-title{
  	font-size: 20px;
  	text-align: center;
  }
  .home-front-button{
  	font-size: 10px;
  }
}

.home-sell-wrapper{
	margin-top: 55px;
	padding-left: 200px;
	padding-right: 200px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 50px;
}

.home-sell-text{
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 20px;
	line-height: 32px;
	margin-top: 10px;
	cursor: pointer;
	text-align: center;

}

.home-sell-button{
	font-family: Gill Sans W01,Arial,sans-serif;
	font-size: 11px;
	letter-spacing: .15em;
	border: 1px solid #999;
	height: 40px;
	width: fit-content;
	display: flex;
	align-items: center;
	padding: 0px 30px;
	margin-top: 40px;
	cursor: pointer;
	background-color: white;
	filter: invert(0%);
	transition: filter 0.5s;
}


.home-sell-button:hover{
	filter: invert(100%);
}

@media only screen and (max-width: 1024px) {
  .home-sell-wrapper{
  	padding-left: 10vw;
  	padding-right: 10vw;
  }
  .home-sell-text{
  	font-size: 16px;
  	line-height: 20px;
  }
  .home-sell-button{
  	font-size: 10px;
  }
}

.home-featured-wrapper{
	margin-top: 75px;
	background-color: #f5f1ed;
	padding-top: 50px;
	padding-bottom: 50px;
}

.home-featured-top{
	display: flex;
	align-items: center;
}

.home-featured-title{
	font-family: Gill Sans W01,Arial,sans-serif;
	font-size: 11px;
	letter-spacing: .15em;
	flex-basis: 100%;
	width: 100%;
	text-align: center;
}

.home-featured-button{
	font-family: Gill Sans W01,Arial,sans-serif;
	font-size: 11px;
	letter-spacing: .15em;
	border: 1px solid #999;
	height: 40px;
	width: fit-content;
	display: flex;
	align-items: center;
	padding: 0px 30px;
	cursor: pointer;
	background-color: #f6f4f4;
	filter: invert(0%);
	transition: filter 0.5s;
	position: absolute;
	right: 10vw;
}


.home-featured-button:hover{
	filter: invert(100%);
}

.home-featured-carousel{
	padding-left: 5vw;
	padding-right: 5vw;
	display: flex;
	justify-content: space-between;
}

.home-featured-element{
	cursor: pointer;
	width: 25vw;
	position: relative;

}

.home-featured-image-1{
	width: 100%;
	position: absolute;
	opacity: 100%;
	transition: opacity 0.4s;
}

.home-featured-image-1:hover{
	opacity: 0%;
}

.home-featured-image-2{
	position: absolute;
	width: 100%;
	opacity: 0%;
	transition: opacity 0.4s;
}

.home-featured-image-2:hover{
	opacity: 100%;
}

.home-featured-bottom{
	margin-top: 50px;
}

.home-featured-text{
	display: flex;
	padding-top: 18vw;
	width: 100%;
	justify-content: space-between;
}

@media only screen and (max-width: 1024px) {
  .home-featured-carousel{
  	flex-direction: column;
  	align-items: center;
  	margin-top: 25px;
  }
  .home-featured-element{
  	width: 50vw;
  }
  .home-featured-text{
  	padding-top: 37vw;
  	padding-bottom: 9vw;
  	font-size: 11px;
  }
  .home-featured-button{
  	position: relative;
  	right: auto;
  }
  .home-featured-bottom{
  	display: flex;
  	justify-content: center;
  	margin-top: 0px;
  }
}

.home-appraisal-wrapper{
	background-color: #111;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 50px;
	padding-bottom: 50px;
}

.home-appraisal-title{
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 24px;
	line-height: 32px;
	margin-top: 10px;
	cursor: pointer;
	text-align: center;
	color: white;
}

.home-appraisal-button{
	font-family: Gill Sans W01,Arial,sans-serif;
	font-size: 11px;
	letter-spacing: .15em;
	border: 1px solid #fff;
	height: 40px;
	width: fit-content;
	display: flex;
	align-items: center;
	padding: 0px 30px;
	cursor: pointer;
	background-color: #111;
	filter: invert(0%);
	transition: filter 0.5s;
	color: white;
	margin-top: 35px;
	text-align: center;
}


.home-appraisal-button:hover{
	filter: invert(100%);
}

@media only screen and (max-width: 1024px) {
	.home-appraisal-title{
		font-size: 20px;
	}
}

.home-info-wrapper{
	width: 100%;
	position: relative;
	height: 43vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-image: url('./HomeInfoD.png');
	justify-content: center;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
	
.home-info-main{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #0000008a;
	padding-bottom: 30px;

}

.home-info-text{
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 24px;
	line-height: 32px;
	margin-top: 50px;
	text-align: center;
	margin-left: 100px;
	margin-right: 100px;
	color: #fff;
}

.home-info-button{
	font-family: Gill Sans W01,Arial,sans-serif;
	font-size: 11px;
	letter-spacing: .15em;
	border: 1px solid #000;
	height: 40px;
	width: fit-content;
	display: flex;
	align-items: center;
	padding: 0px 30px;
	cursor: pointer;
	filter: invert(0%);
	transition: filter 0.5s;
	transition: background-color 0.5s;
	margin-top: 35px;
	background-color: #0000008a;
	color: #fff;
}


.home-info-button:hover{
	filter: invert(100%);
	background-color: #000;
	border: 1px solid #000;
}

@media only screen and (max-width: 1024px) {
	.home-info-text{
		margin-top: 10vh;
		font-size: 20px;
	}
	.home-info-wrapper{
		height: 50vh;
	}
	.home-info-text{
		margin-left: 10px;
		margin-right: 10px;
		margin-top: 15vw;
	}
}

.home-featured-wrapper2{
	background-color: #f5f1ed;
	padding-top: 50px;
	padding-bottom: 50px;
}

.home-sponsor-wrapper{
	display: flex;
	flex-direction: column;
	padding-bottom: 50px;
}

.home-sponsor-title{
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 20px;
	line-height: 32px;
	margin-top: 50px;
	text-align: center;
	margin-left: 100px;
	margin-right: 100px;
	color: #000;
}

.home-sponsor-element{
	display: flex;
	justify-content: center;
	margin-top: 25px;
}

.home-sponsor-image1{
	width: 100px;
	margin-right: 100px;
}

.home-sponsor-image2{
	width: 150px;
}

@media only screen and (max-width: 1024px) {
	.home-sponsor-title{
		font-size: 15px;
	}

}

@media only screen and (max-width: 600px){
	.home-sponsor-image1{
		width: 70px;
		margin-right: 70px;
	}
	.home-sponsor-image2{
		width: 120px;
	}
}