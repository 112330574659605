.bigwrapper{
	position: absolute;
	left: 0%;
	width: 100%;
	transition: left 0.5s;
}

.bigwrapper[ismenuopen="true"]{
	left: 50%;
}

.wrapper{
}


.main-wrapper{
	min-height: 100vh;
}