.menu-products-wrapper{
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-left: 5vw;
    margin-right: 5vw;
}

.menu-product-wrapper{
    flex-basis: calc(33.3% - 6vw);
    height: 15vw;
    display: block;
    margin-bottom: 10vw;
    margin-left: 3vw;
    margin-right: 3vw;
	position: relative;
}

.menu-product-images{
    width: 100%;
    height: 100%;
    display: block;
}

.menu-product-image-1{
	width: 100%;
    height: 100%;
    display: block;
	position: relative;
	opacity: 100%;
	transition: opacity 0.4s;
    object-fit: cover;
}

.menu-product-image-1:hover{
	opacity: 0%;
}

.menu-product-image-2{
	width: 100%;
    height: 100%;
    display: block;
	position: relative;
    top: -100%;
	opacity: 0%;
	transition: opacity 0.4s;
    object-fit: cover;
}

.menu-product-image-2:hover{
	opacity: 100%;
}

.menu-product-text{
	display: flex;
	width: 100%;
	justify-content: space-between;
    font-family: Gill Sans W01,Arial,sans-serif;
	font-size: 13px;
    line-height: 18px;
}


.menu-products-title{
	font-family: Gill Sans W01,Arial,sans-serif;
	font-size: 11px;
	letter-spacing: .15em;
	flex-basis: 100%;
	width: 100%;
	text-align: center;
}



@media only screen and (max-width: 1024px) {
    .menu-products-wrapper{
        margin-left: 0px;
        margin-right: 0px;
    }
    .menu-product-wrapper{
        flex-basis: calc(100% - 20vw);
        margin-left: 10vw;
        margin-right: 10vw;
        height: 45vw;
        margin-bottom: 160px;
    }
}

@media only screen and (max-width: 580px) {
    .menu-products-wrapper{
        margin-left: 0px;
        margin-right: 0px;
    }
    .menu-product-wrapper{
        flex-basis: calc(100% - 20vw);
        margin-left: 10vw;
        margin-right: 10vw;
        height: 45vw;
        margin-bottom: 160px;
    }
}