.footer-wrapper{
	background-color: #111;
	color: white;
	padding-top: 50px;
	padding-bottom: 25px;
	padding-left: 12vw;
	padding-right: 12vw;
	display: flex;
	font-family: Gill Sans W01,Arial,sans-serif;
}

.footer-about{
	flex-basis: 33%;
	width: 33%;
}

.footer-title{
	font-size: 14px;
}

.footer-text{
	font-size: 14px;
	margin-top: 20px;
	cursor: pointer;
	transition: transform 0.4s;
	width: fit-content;
}

.footer-text:hover{
	transform: scale(1.1, 1.1);
}

.footer-contact{
	flex-basis: 33%;
	width: 33%;
}

.footer-misc{
	flex-basis: 33%;
	width: 33%;
}

.footer-title2{
	font-size: 13px;
	margin-bottom: 20px;
}


.footer-social{
	margin-top: 50px;
}

.footer-social-element{
	display: flex;

}



.footer-subscribe-image{
	height: 40px;
	cursor: pointer;
	filter: invert(100%);
	transition: transform 0.5s;
}

.footer-subscribe-image:hover{
	transform: scale(1.5, 1.5);
}

.footer-social-image{
	height: 20px;
	cursor: pointer;
	margin-right: 25px;
	filter: invert(100%);
	transition: transform 0.5s;
}

.footer-social-image:hover{
	transform: scale(1.5, 1.5);
}

.footer-copyright{
	display: flex;
	margin-top: 45px;
}

.footer-copyright-element{
	font-size: 11px;
	margin-right: 20px;
	cursor: pointer;
}

.footer-subscribe-form{
	cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .footer-wrapper{
  	padding-left: 25px;
  	padding-right: 25px;
  }
}

@media only screen and (max-width: 560px) {
  .footer-wrapper{flex-direction: column;}
  .footer-about{
  	width: 100%;
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	margin-bottom: 50px;
  }
  .footer-contact{
  	width: 100%;
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	margin-bottom: 50px;
  }
  .footer-misc{
  	width: 100%;
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  }
  .footer-subscribe{
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  }
  .footer-social{
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  }
  .footer-social-image{
  	margin-right: 0px;
  }
  .footer-social-element{
  	width: 100%;
  	justify-content: space-evenly;
  }
  .footer-copyright{
  	justify-content: space-evenly;
  }
  .footer-copyright-element{
  	margin-right: 5px;
  }
}