
.blocks-wrapper{
    padding-left: 10%;
    padding-right: 10%;
}

.block-text-wrapper{
    width: auto;
    color: black;
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
    font-family: Minion W01,Times,Times New Roman,serif !important;
    font-size: 18px;
}

.block-text-wrapper > h1{
    font-size: 22px;
}

.block-text-wrapper > h2{
    font-size: 20px;
}

.block-text-wrapper > h3{
    font-size: 18px;
}

.block-image-wrapper{
    width: fit-content;
    color: black;
    margin: auto;
    text-align: justify;
    font-family: Minion W01,Times,Times New Roman,serif !important;
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 16px;
}

.block-image-wrapper > p{
    text-align: center;
}

.block-image-wrapper > img{
    max-height: 400px;
}

.ql-editor > h1{
    font-size: 22px !important;
    font-family: Minion W01,Times,Times New Roman,serif !important;
}

.ql-editor > h2{
    font-size: 20px !important;
    font-family: Minion W01,Times,Times New Roman,serif !important;
}

.ql-editor > h3{
    font-size: 18px !important;
    font-family: Minion W01,Times,Times New Roman,serif !important;
}

.ql-editor > p{
    font-size: 16px !important;
    font-family: Minion W01,Times,Times New Roman,serif !important;
}

.block-bottom-wrapper{
    display: flex;
}

.block-reorder-wrapper{
    margin-left: 20px;
    font-size: 30px;
    display: flex;
}

.block-reorder-button{
    margin-left: 10px;
    background-color: #8f2f289e;
    color: white;
    border: 1px solid black;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    height: 40px;
    cursor: pointer;
    transition: filter 0.5s;
}

.block-reorder-button:hover{
    filter: invert(100%);
}

@media only screen and (max-width: 1024px) {
.blocks-wrapper {
    padding-left: 2%;
    padding-right: 2%;
}

}

@media only screen and (max-width: 576px){
    .block-text-wrapper{
        font-size: 14px;
    }
}