body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
}

a {
  text-decoration: none;
  color: inherit; 
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.thumb{
  border: none !important;
}

.checkbox-wrapper{
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.checkbox-button{
  width: 20px;
  height: 20px;
  min-height: 20px;
  min-width: 20px;
  border: 1px solid #000;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.5s;
  margin-right: 10px;
  border-radius: 2px;
}

.checkbox-button[check="true"]{
  background-color: #999;
}

.checkbox-text{
  font-family: Gill Sans W01,Arial,sans-serif;
  font-size: 12px;
  letter-spacing: .1em;
  margin-right: 15px;
  transform: scale(1, 1) !important;
}

::selection{
  background: transparent;
  color: #afc9a2;
  text-shadow: none;
}

.label{
  position: absolute;
  margin-top: -25px;
  font-size: 14px;
  color:#999;
}

input {
  all: unset;
}

.user-input{
  background-color: rgb(255, 255, 255);
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid rgb(190, 190, 190);
  border-radius: 2px;
  transition: border 0.5s;
  width: fit-content;
}

.user-input:focus{
  border: 1px solid black;
}

.text-input{
  cursor: pointer;
}

.quill-wrapper{
  margin-top: 50px;
  margin-bottom: 10px;
}