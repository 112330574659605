.cookie-wrapper{
    position: fixed;
    bottom: 0px;
    background-color: #ffffffd6;
    height: 200px;
    font-family: Gill Sans W01,Arial,sans-serif;
    opacity: 1;
    transition: opacity 1.5s;
    z-index: 10;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100vw;
}

.cookie-wrapper[show='false']{
    opacity: 0;
    pointer-events: none;
}

.cookie-text-wrapper{
    margin-top: 12px;

}

.cookie-title{
    font-size: 18px;
    text-align: center;
}

.cookie-description{
    text-align: justify;
    margin-top: 15px;
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
}

.cookie-button-wrapper{
    text-align: center;
    display: flex;
    margin-top: 15px;
}

.cookie-button{
    padding: 10px;
    border: 1px solid #9b9797;
    width: 150px;
    cursor: pointer;
    filter: none;
    transition: filter .5s;
    background-color: white;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
}

.cookie-button:hover{
    filter: invert(100%);
    border: 1px solid white;
}

.cookie-bottom-wrapper{
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.cookie-navlink{
    color: gray;
}

.cookie-link{
    color: gray;
    font-style: italic;
    font-size: 12px;
    cursor: pointer;
    transition: transform .2s;
    transform: none;
    margin-left: 5px;
    margin-right: 5px;
}

.cookie-link:hover{
    transform: scale(1.1, 1.1)
}

@media only screen and (max-width: 1024px) {
    .cookie-wrapper{
        
    }
    .cookie-title{
        font-size: 14px;
    }
    .cookie-description{
        font-size: 12px;
    }
    .cookie-button{
        font-size: 10px;
    }
    .cookie-link{
        font-size: 9px;
    }
}

@media only screen and (max-width: 539px) {
    .cookie-wrapper{
    
    }
    .cookie-title{
        font-size: 13px;
    }
    .cookie-description{
        font-size: 11px;
    }
    .cookie-button{
        font-size: 9px;
        padding: 5px;
    }
    .cookie-link{
        font-size: 8px;
    }
    .cookie-bottom-wrapper{
        margin-top: 10px;
    }
    .cookie-button-wrapper{
        margin-top: 10px;
    }
}