.menuarticle-wrapper{
    padding: 10px;
    font-family: Gill Sans W01,Arial,sans-serif;
    line-height: 25px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #f5f1eda9;
    transition: border-bottom .5s;
}

.menuarticle-wrapper:hover{
	border-bottom: 1px solid gray;
}

.menuarticle-image-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.menuarticle-image{
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    width: 100%;
    display: flex;
    max-height: 80%;
    margin-bottom: 1vw;
    box-shadow: 0px 0px 5px -2px black;
}

.menuarticle-info{
    display: flex;
    flex-direction: column;
    max-width: fit-content;
}

.menuarticle-title{
    font-size: 16px;
}

.menuarticle-date{
    font-size: 14px;
    color: gray;
}

.menuarticle-category{
    font-size: 14px;
    text-decoration:underline;
    color: #989898;
}

