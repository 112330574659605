.login-wrapper{
    background-color: #f5f1ed;
    border-radius: 10px;
    box-shadow: 2px 2px 10px gray;
    color: #000000;
    padding: 70px;
    width: 200px;
    height: 300px;
    margin: auto;
    margin-top: 50px;
    
}

.login-logo{
    width: 170px;
    padding-bottom: 20px;
}
 .login-input{
    border:none;
    border-radius: 2px;
 }
.login-username{
    padding-bottom:5px;
}
.login-forgot{
    cursor:pointer;
    font-family: Minion W01,Times,Times New Roman,serif;
    letter-spacing: 0.05em;
    padding-top: 10px;
    padding-bottom: 10px;
}

.login-button{
    cursor:pointer;
    font-family: Minion W01,Times,Times New Roman,serif;
    padding-top: 10px;
    font-size: 15px;
    letter-spacing: .15em;
    border: 1px solid #000;
    height: 40px;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 0px 30px;
    cursor: pointer;
    filter: invert(0%);
    transition: filter 0.5s;
}

.login-button:hover{
	filter: invert(60%);
	border: 1px solid #000;
}

.login-response{
    font-family: Minion W01,Times,Times New Roman,serif;
    padding-top: 10px;
    font-style: italic;
    display: flex;
    justify-content: center;
}