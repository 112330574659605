.featured-products-wrapper{
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    justify-content: center;
}

.menu-products-admin-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.home-featured-remove{
    font-family: Gill Sans W01,Arial,sans-serif;
    font-size: 11px;
    letter-spacing: .15em;
    border: 1px solid #000;
    height: 40px;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    cursor: pointer;
    filter: invert(0%);
    transition: filter 0.5s;
    background-color: #8f2f289e;
    color: #fff;
    margin-bottom: 15px;
    margin-top: 15px;
}

.home-featured-directions-wrapper{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.home-featured-left{
font-family: Gill Sans W01,Arial,sans-serif;
    font-size: 11px;
    letter-spacing: .15em;
    border: 1px solid #000;
    height: 40px;
    width: 90px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    cursor: pointer;
    filter: invert(0%);
    transition: filter 0.5s;
    background-color: #8f2f289e;
    color: #fff;
    text-align: center;
    justify-content: center;
}


.home-featured-right{
    font-family: Gill Sans W01,Arial,sans-serif;
    font-size: 11px;
    letter-spacing: .15em;
    border: 1px solid #000;
    height: 40px;
    width: 90px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    cursor: pointer;
    filter: invert(0%);
    transition: filter 0.5s;
    background-color: #8f2f289e;
    color: #fff;
    justify-content: center;
}

.home-featured-left:hover , 
.home-featured-right:hover,
.home-featured-remove:hover{
    filter: invert(100%);
}

.featured-select{
    width: 200px;
    margin-bottom: 15px;
}

.featured-select-wrapper{
    display: flex;
    justify-content: center;
    margin-top: 40px;
}