.viewing-wrapper{
    position: absolute;
    height: 0px;
    width: 0px;
    z-index: -1;
    opacity: 0;
    top: 0px;
    left: 0px;
    transition: opacity 0.5s;

}

.viewing-wrapper[viewing='true']{
    z-index: 99 ;
    opacity: 1;
    position: fixed;
    width: 100vw;
    height: 100vh;
}

.viewing-wrapper[viewing='false']{
    z-index: -1;
    opacity: 0;
    animation: zindexout 0.5s;
    animation-fill-mode: forwards;
    
}

@keyframes zindexout{
    0%{z-index: 10;}
    99%{z-index: 10;}
    100%{z-index: -1;}
}


.viewing-blur{
    position: absolute;
    width: 100vw;
    height: 100vh;
    opacity: 0.5;
    background-color: #f5f1ed;
    top: 0px;
    left: 0px;
}

.viewing-element-wrapper{
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 12;
}

.viewing-element{
    height: 800px;
    width: 1000px;
    background-color: #fff;
    position: relative;
    max-width: 100vw;
    max-height: 100vh;
    overflow-y: auto;
}

.viewing-close{
    position: absolute;
    top: 15px;
    right: 15px;
    width: 12px;
    cursor: pointer;
    opacity: 1 !important;
    z-index: 99;
}

.viewing-form{
    transition: opacity 0.5s;
    padding: 50px;
}

.viewing-title{
    font-family: Gill Sans W01,Arial,sans-serif;
    font-size: 14px;
    letter-spacing: .1em;
}

.viewing-name{
    margin-top: 30px;
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 26px;
    line-height: 32px;
}

.viewing-location{
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 26px;
    line-height: 32px;
}

.viewing-form-elements{
    margin-top: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr; 
    grid-row-gap: 30px;
    grid-column-gap: 50px;
    width: 100%; 
}

.viewing-form-field{
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 16px;
    margin-bottom: 5px;
}

.viewing-form-box-select{
    width: 102%;
    height: 41px;
}

.viewing-form-input{
    height: 35px;
    width: 100%;
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 17px;
}

.viewing-form-select{
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 17px;

}

.viewing-form-checkbox{
    margin-top: 25px;
    display: flex;
}

.viewing-form-check{
    width: 20px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    border: 1px solid #000;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.5s;
    margin-right: 10px;
    border-radius: 2px;
}

.viewing-form-check[check='true']{
    background-color: #999;
}

.viewing-form-text{
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 16px;
}

.viewing-form-submit{
    margin-top: 35px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 250px;
    height: 40px;
    background-color: #000;
    color: white;
    font-family: Gill Sans W01,Arial,sans-serif;
    font-size: 11px;
    letter-spacing: .15em;
    filter: none;
    transition: filter 0.5s;
    cursor: pointer;
    position: relative;
    left: calc(50% - 125px);
}

.viewing-form-submit:hover{
    filter: invert(100%);
}


@media only screen and (max-width: 600px) {
    .viewing-form-elements{
        display: block;
    }
    .viewing-form-element{
        margin-top: 25px;
    }
    .viewing-form-checkbox{
        margin-top: 35px;
    }
    .viewing-form-submit{
        margin-top: 40px;
    }
}

.viewing-form-thank{
    position: absolute;
    width: 100%;
    top: 100px;
    text-align: center;
    opacity: 0;
    
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 26px;
    line-height: 32px;
    transition: opacity 0.5s;
    z-index: -1;  
}

.viewing-form[send='true']{
    opacity: 0;
}

.viewing-form-thank[send='true']{
    opacity: 1;
    z-index: 9;
}

.viewing-form-thank-image{
    width: 185px;
    position: absolute;
    top: -100px;
    right: 175px;
}

.viewing-form-thank-text1{
    text-align: left;
    margin-left: 246px;
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: .03em;
    transform: scale(1.05, 1);
    margin-top: 150px;
    width: fit-content;
}

.viewing-form-thank-text2{
    text-align: left;
    margin-left: 305px;
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: .03em;
    transform: scale(1, 1.05);
    margin-top: 20px;
    width: fit-content;
}

.viewing-form-thank-button{
    font-family: Minion W01,Times,Times New Roman,serif;
    position: absolute;
    font-size: 11px;
    letter-spacing: .15em;
    height: 40px;
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 0px 30px;
    cursor: pointer;
    background-color: #000;
    filter: invert(0%);
    transition: filter 0.5s;
    opacity: 1;
    top: 575px;
    right: 100px;
    color: white;
}

.viewing-form-thank-button:hover{
    filter: invert(100%); 
}

@media only screen and (max-width: 930px) {
    .viewing-form-thank-image{
        right: 10vw;
    }
    .viewing-form-thank-button{
        right: 14vw;
    }
    .viewing-form-thank-text1{
        margin-left: 7vw;
    }
    .viewing-form-thank-text2{
        margin-left: 10vw;
    }
}

@media only screen and (max-width: 575px) {
    .viewing-form-thank-image{
        right: 20px;
        width: 160px;
    }
    .viewing-form-thank-button{
        right: 40px;
    }
    .viewing-form-thank-text1{
        margin-left: 20px;
        font-size: 24px;
    }
    .viewing-form-thank-text2{
        margin-left: 25px;
        font-size: 18px;
        margin-top: 20px;
    }

}

@media only screen and (max-width: 400px) {
    .viewing-form-thank-image{
        right: 20px;
        width: 160px;
    }
    .viewing-form-thank-button{
        right: 40px;
    }
    .viewing-form-thank-text1{
        margin-left: 20px;
        font-size: 18px;
        margin-top: 320px;
    }
    .viewing-form-thank-text2{
        margin-left: 40px;
        font-size: 16px;
        margin-top: 20px;
    }

}

.viewing-form-field[rq="true"]{
    color: #bb5353;
}

.viewing-form-text[rq="true"]{
    color: #bb5353;
}