.article-background{
    background-color: #f5f1ed;
    min-height: 100vh;
    padding-top: 15px;
    padding-bottom: 15px;
}

.article-wrapper{
    background-color: #ffffff8f;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 20px;
    padding-top: 35px;
}

.article-title-wrapper{
    width: fit-content;
    margin: auto;
}

.article-title{
    color: black;
    text-align: center;
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 24px;
    cursor: default;
    padding-bottom: 5px;
    border-bottom: 1px solid #c4c4c4;
    width: fit-content;
    margin: auto;
    margin-top: 35px;
}

.article-info-wrapper{
    display: flex;
    padding-top: 10px;
    font-family: Gill Sans W01,Arial,sans-serif;
    color: #a0a0a0;
    font-size: 15px;
    margin-bottom: 50px;
}

.article-info-divider{
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 4px;
    border-left: 1px solid #c4c4c4;
}

.article-info-category{
}



.article-admin-wrapper{
    position: sticky;
    top: 68px;
    background-color: #f5f1eda9;
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 100px;
    margin-right: 100px;
    backdrop-filter: blur(2px);
}

.article-admin-button{
    width: 150px;
    left: calc(50% - 75px);
    text-align: center;
    cursor: pointer;
    font-family: Gill Sans W01,Arial,sans-serif;
    font-size: 11px;
    letter-spacing: .15em;
    border: 1px solid #000;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    cursor: pointer;
    filter: invert(0%);
    transition: filter 0.5s;
    background-color: #8f2f289e;
    color: #fff;
    justify-content: center;
    line-height: 1.5em;
}



.article-admin-button:hover{
    filter: invert(100%);
}

.article-featured-image{
    width: 50px;
}

.unselected-wrapper{
    border: 1px solid transparent;
    transition: border 0.2s;
}

.unselected-wrapper:hover{
    border: 1px solid black;
    cursor: pointer;
}

.selected-wrapper{
    border: 1px solid black;
    padding: 10px;
}





.article-author-wrapper{
    display: flex;
    font-family: Gill Sans W01,Arial,sans-serif;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-left: 15vw;
    align-items: center;
}

.article-author-right{
    margin-left: 20px;
    margin-top: 20px;
    line-height: 25px;
}


.article-author-image{
    height: 9vw;
    min-height: 150px;

}



.article-author-name{
    font-size: 16px;
    font-weight: 600;
}

.article-author-title{
    font-size: 14px;
    font-weight: 400;
    color: lightgray;
}

.article-author-description{
    font-size: 14px;
    color: gray;
    font-weight: 400;
}

.article-author-links{
    margin-top: 10px;
}

.article-author-link{
    height: 25px;
    cursor: pointer;
    filter: invert(50%);
    transition: filter 0.3s;
}

.article-author-link:hover{
    filter: invert(0%);
}

@media only screen and (max-width: 1024px) {
    .article-wrapper{
        margin-left: 3%;
        margin-right: 3%;
    }
    .article-author-image {
        height: 13vw;
    }
    .article-title-wrapper{
        padding-left: 5px;
        padding-right: 5px;
    }
    .article-info-wrapper{
        font-size: 14px;
    }
    .article-admin-wrapper{
        margin-left: 0px;
        margin-right: 0px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 25px;
        margin-left: 5px;
        margin-right: 5px;
        height: auto;
        top: 48px;
        padding-top: 10px;

    }
    
}


@media only screen and (max-width: 670px) {
    .article-admin-wrapper{
        grid-template-columns: repeat(2, 1fr);
    }

    .article-author-image {
        height: 13vw;
    }
}

@media only screen and (max-width: 530px) {
    .article-author-wrapper{
        display: block;
        margin-left: 0px;
        margin-right: 0px;
        text-align: center;
    }
    .article-author-right{
        margin-left: 0px;
    }
}


@media only screen and (max-width: 490px) {
    .article-admin-wrapper{
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        position: relative;
    }
}