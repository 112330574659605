.privacy-wrapper{
    background-color: #f5f1ed;
    padding-left: 15vw;
    padding-right: 15vw;
    padding-top: 50px;
    padding-bottom: 100px;
    font-family: Minion W01,Times,Times New Roman,serif;
    text-align: justify;
}

.privacy-title{
    font-size: 18px;
    margin-bottom: 25px;
    font-weight: 600;
}

.privacy-date{
    font-size: 16px;
    margin-bottom: 20px;
    font-style: italic;
}

.privacy-chapter{
    font-size: 15px;
    font-weight: 600;
    line-height: 50px;
}

.privacy-subchapter{
    font-size: 15px;
    font-weight: 600;
    line-height: 35px;
}

.privacy-text{
    font-size: 15px;
    line-height: 24px;
}

.privacy-chapter-end{
    height: 50px;
}

.privacy-subchapter-end{
    height: 25px;
}