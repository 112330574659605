.headerside-wrapper{
	position: absolute;
	left: -50vw;
	height: 100vh;
	top: 0;
	width: 50vw;
}

.headerside-top{
	margin-top: 10vh;
	margin-left: 30px;
	font-size: 14px;
	font-family: Gill Sans W01,Arial,sans-serif;
	letter-spacing: .1em;
}

.headerside-top-element{
	margin-bottom: 25px;
	cursor: pointer;
	border-bottom: 1px solid white;
	transition: border-bottom 0.5s;
	width: fit-content;
	padding-bottom: 2px;
}

.headerside-top-element:hover{
	border-bottom: 1px solid gray;
}

.headerside-middle{
	margin-top: 20vh;
}

.headerside-middle-element{
	display: flex;
	margin-left: 30px;
	margin-top: 20px;
	font-size: 11px;
	font-family: Gill Sans W01,Arial,sans-serif;
	letter-spacing: .1em;
	cursor: pointer;
	border-bottom: 1px solid white;
	transition: border-bottom 0.5s;
	width: fit-content;
	padding-bottom: 2px;
}

.headerside-middle-element:hover{
	border-bottom: 1px solid gray;
}

.headerside-middle-image{
	height: 20px;
	margin-right: 5px;
}

.headerside-bottom{
	display: flex;
	position: absolute;
	bottom: 50px;
	justify-content: space-evenly;
	width: 50vw;
}

.headerside-bottom-image{
	height: 25px;
	cursor: pointer;
	border-bottom: 1px solid white;
	transition: border-bottom 0.5s;
	padding-bottom: 2px;
}

.headerside-bottom-image:hover{
	border-bottom: 1px solid gray;
}

.headerside-middle-button{
	display: flex;
	margin-bottom: 15px;
}

@media screen and (max-width: 370px){
	.headerside-top{
		font-size: 12px;
	}
}