

.buy-top{
	background-image: url('./buy.svg');
	height: 90px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	justify-content: center;
    
}
.buy-elements{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

}

.buy-header-right{
	flex-basis: 35%;
	justify-content: right;
	display: flex;
    font-family: Gill Sans W01,Arial,sans-serif;
    font-size: 12px;
    letter-spacing: .1em;
    padding-right: 10%;
    padding-top: 25px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    transform: scale(1.07 , .9);
}

.buy-header-right-element{
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid #c8b9aa;
    transition: border-bottom 0.5s;
    margin: 10px;
}

.buy-header-right-element:hover{
    border-bottom: 1px solid black;
}

.buy-header-left{
	justify-content: left;
	display: flex;
    align-items: center;
    padding: 1% 0% 0% 5%;
}

.buy-header-left-element{
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-size: 16px;
    height: 25px;
    padding: 3px 9px;
    width: 100%;
    display: flex;
    align-items: center;
    opacity: 0.5;
    font-family: Minion W01,Times,Times New Roman,serif;

}

.buy-header-left-search{
    height: 14px;
    opacity: 0.7;
    padding: 2px 0px 0px 10px;
    cursor: pointer;

}

.buy-header{
    color: WHITE;
    text-align: center;
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 19px;
    display:flex;
    align-items: center;
}



.buy-admin-wrapper{
    background-color: #f5f1ed;
    
}

.admin-buy-add{
    position: absolute;
    width: 150px;
    left: calc(50% - 75px);
    text-align: center;
    cursor: pointer;
	font-family: Gill Sans W01,Arial,sans-serif;
    font-size: 11px;
    letter-spacing: .15em;
    border: 1px solid #000;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    cursor: pointer;
    filter: invert(0%);
    transition: filter 0.5s;
    background-color: #8f2f289e;
    color: #fff;
    justify-content: center;
	line-height: 1.5em;
	
}
.admin-buy-add:hover{
    filter: invert(100%);
}

@media only screen and (max-width: 1024px) {
    .buy-header-left-element{
        width: 80%;
        font-size:14px;
        height: 22px;
    }
    .buy-header{
        font-size: 17px;
    }
    .buy-header-right{
        padding-right: 8%;
        font-size: 11px;
    }
    .buy-header-left-search{
        height: 12px;
    }
}

@media only screen and (max-width: 800px) {
    .buy-header{
        font-size: 16px;
    }
    .buy-header-right{
        font-size: 10px;
        padding-right: 7%;
    }
    .buy-header-left{
        padding: 2% 0% 0% 5%;
    }
    .buy-header-left-element{
        font-size: 13px;
        height: 20px;
    }
    .buy-header-left-search{
        height: 11px;
    }
}

@media only screen and (max-width: 600px) {
    .buy-header{
        font-size: 15px;
    }
    .buy-header-right{
        margin: -3px;
        font-size: 9px;
        padding-right: 6%;
        padding-left: 7%;
        padding-top: 16px;
    }
    .buy-header-right-element{
    margin: 6px;
    }
    .buy-header-left-element{
        font-size: 12px;
        height: 18px;
        width: 60%;
    }
    .buy-header-left-search{
        height: 9px;
        padding: 2px 0px 0px 4px;
    }
}

@media only screen and (max-width: 400px) {
    .buy-header{
        font-size: 14px;
    }
    .buy-header-right{
       padding-left: 6%;
       padding-bottom: 1%;
       font-size: 8px;
    }
    .buy-header-left-search{
        padding: 2px 0px 0px 2px;
        font-size: 9px;
    }
    .buy-header-left-element{
      font-size: 10px;
    }
}

.buy-filter-wrapper{
    display: block;
    position: absolute;
    opacity: 0;
    width: fit-content;
    height: fit-content;
    transition: opacity 0.3s;
    background-color: white;
    padding: 25px;
    pointer-events: none;
    top: 50px;
    cursor: default;
}

.buy-filter-text{
    margin-bottom: 25px;
    position: relative;
}

.buy-filter-select{
    margin-bottom: 25px;
    position: relative;
}

.buy-header-wrapper{
    width: 100%;
    height: 100%;
    position: relative;
	z-index: 10;
   
}

.buy-header-right-element:hover > .buy-filter-wrapper{
    opacity: 1;
    pointer-events: auto;
}

.buy-filter-wrapper:hover{
    opacity: 1;
    pointer-events: auto;
}


@media screen and (max-width: 700px){
	.buy-filter-wrapper{
		right: 10%;
	}
}