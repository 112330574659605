.gmap-popup-wrapper{
    width: 100% ;
    height: 100%;
    opacity: 0;
    position: relative;
    transform: translateY(-100%);
}

.gmap-popup-wrapper[show='true']{
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    position: fixed;
    z-index: 15;
    transform: translateY(0%);
    opacity: 1;
}


.gmap-popup-fullscreen{
    width: 0px;
    opacity: 0;
    height: 0px;
    pointer-events: none;
}

.gmap-popup-fullscreen[show='true']{
    width: 100%;
    opacity: 1;
    height: 100%;
    pointer-events: all;
}

.gmap-popup-close{
    width: 30px;
    height: 30px;
    z-index: 16;
    position: fixed;
    top: 10px;
    right: 0px;
    background-color: white;
    padding: 10px;
    border: 1px solid gray;
}