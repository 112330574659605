.desktop-header-wrapper{
	display: flex;
	padding-left: 50px;
	padding-right: 50px;
	padding-top: 25px;
	font-size: 11px;
	font-family: Gill Sans W01,Arial,sans-serif;
	letter-spacing: .1em;
	position: sticky;
	background-color: white;
	top: 0px;
	z-index: 11;
}

.desktop-header-left{
	flex-basis: 35%;
	width: 35%;
	justify-content: left;
	display: flex;
}

.desktop-header-left-element{
	margin-right: 40px;
	padding-bottom: 25px;
	cursor: pointer;
	border-bottom: 1px solid white;
	transition: border-bottom 0.5s;
}

.desktop-header-left-element:hover{
	border-bottom: 1px solid gray;
}


.desktop-header-middle{
	flex-basis: 30%;
	width: 30%;
	display: flex;
	justify-content: center;
	font-size: 12px;
}

.desktop-header-logo{
	position: absolute;
	width: 137px;
	top: 5px;
	cursor: pointer;
}

.desktop-header-right{
	flex-basis: 35%;
	width: 35%;
	display: flex;
	justify-content: right;
}

.desktop-header-right-element{
	margin-left: 40px;
	padding-bottom: 25px;
	cursor: pointer;
	border-bottom: 1px solid white;
	transition: border-bottom 0.5s;
	display: flex;
}

.desktop-header-right-text{
	width: 100%;
}

.desktop-header-right-image{
	padding-right: 5px;
	width: 17px;
}

.desktop-header-right-element:hover{
	border-bottom: 1px solid gray;
}


@media only screen and (max-width: 1240px) {
  .desktop-header-right-element{
  	margin-left: 20px;
  }
}

.mobile-header-wrapper{
	display: flex;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 20px;
	font-size: 11px;
	font-family: Gill Sans W01,Arial,sans-serif;
	letter-spacing: .1em;
	position: sticky;
	background-color: white;
	top: 0px;
	z-index: 11;
}

.mobile-header-left{
	flex-basis: 25%;
	width: 25%;
	justify-content: left;
	display: flex;
}

.mobile-header-left-element{
	margin-right: 40px;
	padding-bottom: 10px;
	cursor: pointer;
	border-bottom: 1px solid white;
	transition: border-bottom 0.5s;
}


.mobile-header-left-element:hover{
	border-bottom: 1px solid gray;
}


.mobile-header-middle{
	flex-basis: 50%;
	width: 50%;
	display: flex;
	justify-content: center;
}

.mobile-header-logo{
	position: absolute;
	width: 90px;
	top: 5px;
	cursor: pointer;
}

.mobile-header-right{
	flex-basis: 25%;
	width: 25%;
	display: flex;
	justify-content: right;
}

.mobile-header-right-element{
	margin-left: 40px;
	padding-bottom: 10px;
	cursor: pointer;
	border-bottom: 1px solid white;
	transition: border-bottom 0.5s;
}

.mobile-header-right-element:hover{
	border-bottom: 1px solid gray;
}

.mobile-header-left-image{
	width: 17px;
}

.mobile-header-right-image{
	width: 20px;
}

@media screen and (max-width: 1140px){
	.desktop-header-left-element{
		margin-right: 20px;
	}
	.desktop-header-wrapper{
		font-size: 10px;
	}
}