.register-wrapper{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    opacity: 0;
    top: 0px;
    left: 0px;
    transition: opacity 0.5s;

}

.register-wrapper[register='true']{
    z-index: 99 ;
    opacity: 1;
}

.register-wrapper[register='false']{
    z-index: -1;
    opacity: 0;
    animation: zindexout 0.5s;
    animation-fill-mode: forwards;
}

@keyframes zindexout{
    0%{z-index: 10;}
    99%{z-index: 10;}
    100%{z-index: -1;}
}


.register-blur{
    position: absolute;
    width: 100vw;
    height: 100vh;
    opacity: 0.5;
    background-color: #f5f1ed;
    top: 0px;
    left: 0px;
}

.register-element-wrapper{
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 12;
    box-shadow: 0px 0px 20px #111;
}

.register-element{
    height: 480px;
    width: 685px;
    background-color:#fbf7ee;
    position: relative;
}


.register-close{
    position: absolute;
    top: 15px;
    right: 15px;
    width: 12px;
    cursor: pointer;
    opacity: 1 !important;
    z-index: 99;
}

.register-form{
    
    padding: 50px;
}

.register-title{
    font-family: Gill Sans W01,Arial,sans-serif;
    font-size: 14px;
    letter-spacing: .1em;
}

.register-name{
    margin-top: 20px;
    font-family: Gill Sans W01,Arial,sans-serif;
    font-size: 14px;
    line-height: 32px;
}

.register-location{
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 26px;
    line-height: 32px;
}

.register-form-elements{
    margin-top: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr; 
    grid-row-gap: 30px;
    grid-column-gap: 50px;
    width: 100%; 
}

.register-form-field{
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 16px;
    margin-bottom: 5px;
}

.register-form-box-select{
    width: 102%;
    height: 41px;
}

.register-form-input{
    height: 35px;
    width: 100%;
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 17px;
}

.register-form-select{
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 17px;

}

.register-form-checkbox{
    margin-top: 25px;
    display: flex;
}

.register-form-check{
    width: 20px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    border: 1px solid #000;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.5s;
    margin-right: 10px;
    border-radius: 2px;
}

.register-form-check[check='true']{
    background-color: #999;
}

.register-form-text{
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 16px;
}

.register-form-submit{
    margin-top: 35px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 250px;
    height: 40px;
    background-color: #000;
    color: white;
    font-family: Gill Sans W01,Arial,sans-serif;
    font-size: 11px;
    letter-spacing: .15em;
    filter: none;
    transition: filter 0.5s;
    cursor: pointer;
    position: relative;
    left: calc(50% - 125px);
}

.register-form-submit:hover{
    filter: invert(100%);
}

@media only screen and (max-width: 700px) {
    .register-element{
        max-width: 100vw;
        max-height: 50vh;
    }
}


@media only screen and (max-width: 600px) {
    .register-form-elements{
        display: block;
    }
    .register-form-element{
        margin-top: 25px;
    }
    .register-form-checkbox{
        margin-top: 35px;
    }
    .register-form-submit{
        margin-top: 40px;
    }
    .register-element{
        max-height: none;
        height: auto;
    }
}

.register-form-thank{
    position: absolute;
    width: 100%;
    top: 40%;
    text-align: center;
    opacity: 0;
    transition: opacity 0.5s;
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 25px;
    line-height: 32px;
    letter-spacing: .03em;
    pointer-events: none;
}


.register-form[send='true']{
    opacity: 0;
}

.register-form-thank[send='true']{
    opacity: 1;
    pointer-events: all;
}

.viewing-form-register-back-button{
        font-family: Minion W01,Times,Times New Roman,serif;
        position: absolute;
        font-size: 11px;
        letter-spacing: .15em;
        height: 40px;
        width: fit-content;
        display: flex;
        align-items: center;
        padding: 0px 30px;
        cursor: pointer;
        background-color: #000;
        filter: invert(0%);
        transition: filter 0.5s;
        opacity: 1;
        top: 150px;
        right: 100px;
        color: white;
    }
    .viewing-form-register-back-button:hover{
        filter: invert(100%); 
    }


.register-form-field[rq="true"]{
    color: #bb5353;
}
    
.register-form-text[rq="true"]{
    color: #bb5353;
}