.about-banner{
	background-image: url('./AboutUsD.png');
	height: 44vw;
    width: 100vw;
    display: flex;
    background-size: cover;
}


.about-row1{
	flex-basis: 50%;
	width: 50%;
}

.about-row2{
	flex-basis: 50%;
	width: 50%;
}


.about-title{
	font-size: 65px;
    padding-top: 14vw;
    text-align: center;
    transform: scale(1 , .9);
    font-family: Minion W01,Times,Times New Roman,serif;

}

.about-text{
    display: flex;
    text-align: center;
    padding: 0px 120px;
    text-align: justify;
    font-family: Minion W01,Times,Times New Roman,serif;
    line-height: 25px;
    font-size: 17px;
}

.about-people{
	display:block;
	padding-left: 20vw;
	padding-right: 20vw;
	margin-top: 50px;
	margin-bottom: 50px;

}

.about-people-row-left{
}

.about-people-row-right{
	display: flex;
	justify-content: flex-end;
}

.about-person-wrapper{
	display: flex;
	padding-bottom: 50px;
}

.about-person-image{
     width: 130px;
	 height: 130px;
}

.about-person-text{
	margin-top: 35px;
	margin-left: 20px;
	margin-right: 20px;
}

.about-person-text-right{
	margin-top: 35px;
	margin-left: 20px;
	margin-right: 20px;
	text-align: right;
}

.about-person-name{
    font-weight: 600;
    font-family: Gill Sans W01,Arial,sans-serif;
    font-size: 15px;
    letter-spacing: .03em;
}

.about-person-role{
    font-family: Gill Sans W01,Arial,sans-serif;
    font-size: 13px;
    color: gray;
    letter-spacing: .02em; 	
}

.about-person-description{
	font-family: Minion W01,Times,Times New Roman,serif;
	margin-top: 15px;
	font-size: 15px;
	letter-spacing: 0.04em;
	color: #474545;
	
}
 
.reviews-wrapper{
	width: auto;
	background-color: #f5f1ed;
}
.reviews-top{
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #f5f1ed;
	display: flex;
	justify-content: center;
	align-items: center;

}
.reviews-titles{
	font-size: 25px;
	padding-top: 4%;
    padding-bottom: 5%;
}
.reviews-top-title{
	font-family: Minion W01,Times,Times New Roman,serif;
    display: flex;
	justify-content: center;
	position: relative;
	color:rgb(39, 39, 39);
	left: -13%;
	

}

.reviews-top-title-right{
	font-family: Minion W01,Times,Times New Roman,serif;
	color: rgb(39, 39, 39);
	display: flex;
	justify-content: center;
	position: relative;
	padding-top: 5px;
	margin-left: 10%;
	padding-bottom: 1px;
	border-bottom: 1px solid #ababab;
}

.reviews-person-wrapper{
	width: auto;
	display: flex;
    align-items: center;
	padding-bottom: 5%;
}

.reviews-person-image{
	width: auto;
	width: 15%;
	height: 15%;
    padding: 3vw;
    padding-left: 20vw;
}

.reviews-person-description{
    font-family: Minion W01,Times,Times New Roman,serif;
	margin-top: 15px;
	font-size: 17px;
	letter-spacing: 0.04em;
	color: #474545;
	font-style: italic;
	padding: 5% 40% 5% 0%;
}

.reviews-person-name{
	font-family: Minion W01,Times,Times New Roman,serif;
	letter-spacing: 0.04em;
	font-size: 18px;
    color: #8d837a;
    display: flex;
    justify-content: flex-end;
    padding: 0% 45% 5% 0%;
}


@media only screen and (max-width: 1024px) {
	.about-people{
		padding-left: 20px;
		padding-right: 20px;
	}
	.about-person-description{
		text-align: justify;
	}

	.reviews-person-image{
		width: 20%;
		height: 20%;
	}
	.reviews-person-name{
		font-size: 20px;
	}
	.reviews-titles{
		font-size: 19px;
	}
}

@media only screen and (max-width: 450px) {
	.about-people{
		padding-left: 5px;
		padding-right: 5px;
	}
	.about-person-image{
		width: 100px;
		height: 100px;
	}
	.about-person-name{
		font-size: 12px;
	}
	.about-person-role{
		font-size: 11px;
	}
	.about-person-description{
		margin-top: 10px;
		font-size: 11px;
		text-align: justify;
	
	}
	.about-person-wrapper{
		margin-top: 35px;
		padding: 10px;
	}
	.about-person-text{
		margin-top: 20px;
	}
	.about-person-text-right{
		margin-top: 20px;
	}

	.reviews-person-image{
		width: 30%;
		height: 30%;
	}
}


@media only screen and (max-width: 1350px) {
	.about-title{
		padding-top: 8vw;
	}
	.reviews-titles{
		font-size: 18px;
	}

}

@media only screen and (max-width: 1250px) {
	.about-title{
		padding-top: 3vw;
	}
	.about-text{
		padding: 0px 60px;
	}
	.reviews-person-image{
		width: 20%;
		height: 20%;
	}
}


/* ~~~ MOBILE DESIGN GOES IN HERE ~~~ */
@media only screen and (max-width: 1024px) {
	.about-banner{
	background-image: url('./AboutUsM.png');
		height: 1000px;
		background-position: center;
	}
	.about-title{
		padding-top: 600px;
	}
	.about-row1{
		width: 100vw;
		flex-basis: 100%;
	}
	.about-row2{
		display: none;

	}
	.about-banner{
		display: block;
	}
	.about-text{
		position: relative;
	}
	.about-people-row{
		flex-direction: column;
		align-items: center;
	}
	.reviews-person-image{
		width: 25%;
		height: 25%;
	}
	.reviews-person-description{
		font-size: 16px;
        padding: 10% 25% 5% 0%
		}
		.reviews-person-name{
		font-size: 15px;
		padding: 0% 25% 10% 0%;
		}
}

@media only screen and (max-width: 540px) {
	.about-title{
		font-size: 60px;
		padding-top: 570px;
	}
	.reviews-person-image{
		width: 33%;
		height: 33%;
		padding-left: 15vw;
	}
	.reviews-person-description{
		font-size: 14px;
		padding: 28% 16% 10% 4%;
		}
	.reviews-person-name{
		font-size: 14px;
		padding: 0% 20% 20% 0%;
		}
		.reviews-titles{
		font-size: 18px;
		}
		
}

@media only screen and (max-width: 486px) {
	.about-title{
		padding-top: 550px;
	}
	.reviews-person-image{
		width: 35%;
		height: 35%;
		padding-left: 15vw;
	}
	
}

@media only screen and (max-width: 430px) {
	.about-title{
		font-size: 60px;	
		padding-top: 570px;
	}
	.about-text{
		font-size: 14px;
		padding: 0px 30px;
	}
	.reviews-person-description{
		font-size: 12px;
		padding: 28% 16% 10% 4%;
		}
	.reviews-person-name{
		font-size: 11px;
		padding: 0% 15% 20% 0%;
		}
	.reviews-person-image{
		width: 40%;
		height: 40%;
		padding-left: 3vw;
		}
	.reviews-titles{
		font-size: 16px;
			}
}

@media only screen and (max-width: 370px) {
	.about-title{
		font-size: 40px;
		padding-top: 530px;
	}
	.reviews-person-image{
		width: 40%;
		height: 40%;
		}
	.reviews-person-description{
		font-size: 10px;
		padding: 17% 12% 10% 0%
		}
	}
