.saleentry-main{
	background-color: #f5f1ed;
	width: 100%;
	height: 100%;
	display: flex;
	padding-top: 30px;
	padding-bottom: 200px;
	position: relative;
}

.saleentry-main-left{
	flex-basis: 65%;
	position: relative;
}

.saleentry-desktop-image{
	width: 100%;
	background-image: url('./Carousel1_1.png');
	height: 44vw;
	background-size: cover;
	background-position: center;
	cursor: pointer;
}

.saleentry-desktop-image:hover .saleentry-desktop-button{
	opacity: 1;
}

.saleentry-desktop-button{
	font-family: Minion W01,Times,Times New Roman,serif;
	position: absolute;
	top: 39vw;
	left: 80%;
	font-size: 11px;
	letter-spacing: .15em;
	height: 40px;
	width: fit-content;
	display: flex;
	align-items: center;
	padding: 0px 30px;
	cursor: pointer;
	background-color: #fff;
	filter: invert(0%);
	transition: filter, opacity 0.5s;
	opacity: 0;
}

.saleentry-desktop-button:hover{
	filter: invert(60%); 
}

.saleentry-main-subtitle{
	margin-top: 25px;
	margin-left: 12.5vw;
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 30px;
	font-style: italic;
	line-height: 40px;
}

.saleentry-main-description{
	margin-top: 35px;
	margin-left: 5vw;
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 18px;
	line-height: 1.5em;
}

.saleentry-main-right{
	flex-basis: 30%;
	display: flex;
	flex-direction: column;
	height: 780px;
	padding-left: 5%;
	justify-content: center;
}

.saleentry-main-title{
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 32px;
	line-height: 38px;
}

.saleentry-main-location{
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 18px;
	line-height: 24px;
}

.saleentry-main-price{
	font-family: Minion W01,Times,Times New Roman,serif;
	margin-top: 25px;
	font-size: 17px;
	line-height: 20px;
	display: flex;
	align-items: center;
}

.saleentry-main-sqm-wrapper{
	display: flex;
	margin-top: 25px;
	font-size: 17px;
	line-height: 20px;
	font-family: Minion W01,Times,Times New Roman,serif;
	margin-bottom: 25px;
}

.saleentry-main-sqm{
	margin-right: 3px;
}

.saleentry-main-buytype{
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 17px;
	line-height: 20px;
}

.saleentry-main-brochure{
	margin-top: 20px;
	letter-spacing: .1em;
	font-family: Gill Sans W01,Arial,sans-serif;
	font-size: 12px;
	cursor: pointer;
	transition: transform .2s;
	width:fit-content;
	height: 15px;
}



.saleentry-main-request{
	margin-top: 25px;
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
	width: 250px;
	height: 40px;
	background-color: #000;
	color: white;
	font-family: Gill Sans W01,Arial,sans-serif;
	font-size: 11px;
	letter-spacing: .15em;
	filter: none;
	transition: filter 0.5s;
	cursor: pointer;
}

.saleentry-main-request:hover{
	filter: invert(100%);
}

.saleentry-main-register{
	margin-top: 15px;
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
	width: 250px;
	height: 40px;
	background-color: #000;
	color: white;
	font-family: Gill Sans W01,Arial,sans-serif;
	font-size: 11px;
	letter-spacing: .15em;
	filter: none;
	transition: filter 0.5s;
	cursor: pointer;
}

.saleentry-main-register:hover{
	filter: invert(100%);
}

.saleentry-main-call{
	margin-top: 10px;
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 17px;
	line-height: 20px;
	letter-spacing: .03em;
	cursor: pointer;
}

.saleentry-main-share{
	margin-top: 15px;
}

.saleentry-main-share-title{
	margin-top: 20px;
	letter-spacing: .1em;
	font-family: Gill Sans W01,Arial,sans-serif;
	font-size: 12px;
	font-weight: 600;	
}

.saleentry-main-share-element{
	display: flex;
}

.saleentry-main-image{
	margin-right: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	width: 25px;
	cursor: pointer;
	transition: transform 0.5s;
}

.saleentry-main-image:hover{
	transform: scale(1.2, 1.2);
}


.saleentry-carousel{
	position: fixed;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: #f5f1ed;
	transition: opacity 0.5s;
	opacity: 0;
	display: block;
	z-index: -1;
	display: flex;
	justify-content: center;
	max-height: 1080px;


}

.saleentry-carousel[opens='false']{
	opacity: 0;
	transition: opacity 0.5s;
	animation: zindexout 0.5s;
	animation-fill-mode: forwards;
}

@keyframes zindexout{
	0%{z-index: 10;}
	99%{z-index: 10;}
	100%{z-index: -1;}
}

@keyframes zindexin{
	0%{z-index: 15;}
	100%{z-index: 15;}
}


.saleentry-carousel[opens='true']{
	opacity: 1;
	animation: zindexin 0.5s;
	animation-fill-mode: forwards;
}


.saleentry-carousel-close{
	width: 25px;
	position: absolute;
	right: 7vw;
	top: 20px;
	cursor: pointer;
}

.saleentry-desktop-carousel{
	width: 100%;
	position: absolute;
	top: 50px;
	min-width: 1024px;
	max-width: 1180px;
	max-height: 800px;
}

.carousel-image-delete{
	position: absolute;
	text-align: center;
	width: 150px;
	margin-left: calc(50% - 75px);
	background-color: white;
	font-family: Gill Sans W01,Arial,sans-serif;
	padding: 12px;
	font-size: 14px;
	cursor: pointer;
	filter: none;
	transition: filter 0.5s;
	border-radius: 5px;

}

.carousel-image-reorder-left{
	position: absolute;
	background-color: white;
	font-family: Gill Sans W01,Arial,sans-serif;
	padding: 12px;
	font-size: 14px;
	display: flex;
	justify-content: center;
	position: absolute;
	width: 75px;
	margin-left: calc(75% - 38px);
	cursor: pointer;
	transition: filter 0.5s;
	filter: none;
}

.carousel-image-reorder-left:hover{
	filter: invert(100%);
}

.carousel-image-reorder-right{
	position: absolute;
	background-color: white;
	font-family: Gill Sans W01,Arial,sans-serif;
	padding: 12px;
	font-size: 14px;
	display: flex;
	justify-content: center;
	position: absolute;
	width: 75px;
	margin-left: calc(75% + 38px);
	cursor: pointer;
	transition: filter 0.5s;
	filter: none;
}

.carousel-image-reorder-right:hover{
	filter: invert(100%);
}


.carosel-image-reorder-button:hover{
	filter: invert(100%);
}



.carousel-image-delete:hover{
	filter: invert(100%);
}

.carousel{
	height: 80vh;
}

.saleentry-checkboxes{
	position: absolute;
	margin-top: 750px;
}

.saleentry-main-checkbox-wrapper{
	margin-top: 15px;
	margin-left: 150px;
}

@media only screen and (max-width: 1024px) {
	.saleentry-main{
		display: block;
	}
	.saleentry-main-title{
		font-size: 24px;
		line-height: 28px;
		margin-top: 15px;
	}
	.saleentry-main-price{
		font-size: 14px;
	}
	.saleentry-main-buytype{
		font-size: 14px;
	}
	.saleentry-main-request{
		width: 100%;
	}
	.saleentry-main-register{
		width: 100%;
	}
	.saleentry-main-call{
		font-size: 14px;
		text-align: center;
	}
	.saleentry-main-share{
		margin-top: 5px;
	}
	.saleentry-main-share-title{
		font-size: 12px;
	}
	.saleentry-main-subtitle{
		font-size: 18px;
		padding-left: 25px;
		padding-right: 25px;
		margin-left: 0px;
	}
	.saleentry-main-description{
		font-size: 16px;
		padding-left: 25px;
		padding-right: 25px;
		margin-left: 0px;
		margin-top: 10px;
	}
	.saleentry-main{
		height: auto;
	}
	.carousel{
		height: auto;
	}
	.saleentry-checkboxes{
		position: relative;
		margin-top: 0px;
	}
	.carousel-image-reorder-left{
		margin-left: 0px;
		margin-right: 0px;
		left: 0px;
	}
	.carousel-image-reorder-right{
		margin-left: 0px;
		margin-right: 0px;
		right: 0px;
	}
}

.saleentry-details{
	display: block;
	padding-left: 25px;
	padding-right: 25px;
}

.saleentry-main-price-share{
	display: flex;
	justify-content: space-between;
}

.saleentry-interested-wrapper{
	background-color: #414141;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-family: Minion W01,Times,Times New Roman,serif;
	padding-top: 30px;
	padding-bottom: 50px;
}

.saleentry-interested-title{
	font-size: 28px;
	line-height: 32px;

}

.saleentry-interested-subtitle{
	font-size: 16px;
	margin-top: 15px;
	letter-spacing: 0.1em;
	border-bottom: 1px solid #fff;	
}

.saleentry-interested-info{
	display: flex;
	width: 500px;
	justify-content: space-evenly;
	margin-top: 20px;
	font-size: 16px;
}

.saleentry-interested-call{
	cursor: pointer;
}

.saleentry-interested-email{
	cursor: pointer;	
}

.saleentry-interested-request{
	margin-top: 25px;
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
	width: 250px;
	height: 40px;
	background-color: #232323;
	color: #fff;
	font-family: Gill Sans W01,Arial,sans-serif;
	font-size: 11px;
	letter-spacing: .15em;
	filter: none;
	transition: transform 0.5s ease-out, filter 0.5s;
	cursor: pointer;
}

.saleentry-interested-request:hover{
	filter: invert(70%);
	transform: scale(1.1, 1.1);
	
}

@media only screen and (max-width: 1024px) {
	.saleentry-interested-title{
		font-size: 20px;
	}
	.saleentry-interested-subtitle{
		font-size: 14px;
	}
	.saleentry-interested-info{
		font-size: 14px;
		width: 250px;
	}
	
}

.saleentry-featured-wrapper{
	padding-top: 75px;
	background-color: #f5f1ed;
	padding-top: 50px;
	padding-bottom: 50px;
}

.translated-input-wrapper{
	background-color: white;
}



.saleentry-main-mobile[mobile="true"]{
	opacity: 1;
	pointer-events: all;
}

.saleentry-main-mobile[mobile="false"]{
	opacity: 0;
	pointer-events: none;
	height: 0px;
	width: 0px;
	padding: 0px;
	margin: 0px;
	display: none;
}

.saleentry-main-desktop[mobile="false"]{
	opacity: 1;
	pointer-events: all;
}

.saleentry-main-desktop[mobile="true"]{
	opacity: 0;
	pointer-events: none;
	height: 0px;
	width: 0px;
	padding: 0px;
	margin: 0px;
	display: none;
}

.saleentry-main{
	transition: opacity .5s, width .5s;
}
.saleentry-main-delete{
    width: 150px;
    left: calc(50% - 75px);
    text-align: center;
    cursor: pointer;
    font-family: Gill Sans W01,Arial,sans-serif;
    font-size: 11px;
    letter-spacing: .15em;
    border: 1px solid #000;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    cursor: pointer;
    filter: invert(0%);
    transition: filter 0.5s;
    background-color: #8f2f289e;
    color: #fff;
    justify-content: center;
    line-height: 1.5em;
}

.saleentry-main-delete:hover{
    filter: invert(100%);
}

.saleentry-bed{
	width: 30px;
	height: 30px;
	margin-left: 5px;
}

.carousel-image{
	width: 76vw !important;
    object-fit: contain;
}

.carousel-image-wrapper{
}

.carousel {
	.slide {
		display: flex;
	    align-items: center;
	    justify-content: center;
		img {
			height: 100%;
			width: auto;
			max-width: 1170px;
			max-height: 79vh;
		}
	}
	.thumb{
		width: 100%;
		height: 80px;
		object-fit:cover;
		img {
			width: 100% !important;
			height: 100% !important;
		}
	}
	.control-next.control-arrow:before{
		border-left: 13px solid #000;
	}
	.control-prev.control-arrow:before{
		border-right: 13px solid #000;
	}
 }

 .energy-popup{
	width: 100% ;
    height: 100%;
    opacity: 0;
    position: relative;
    transform: translateY(-100%);
 }

 .energy-popup[show='true']{
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    position: fixed;
    z-index: 15;
    transform: translateY(0%);
    opacity: 1;
	background-color: #f5f1ed;
}

.energy-popup-close{
    width: 30px;
    height: 30px;
    z-index: 16;
    position: fixed;
    top: 10px;
    right: 0px;
    background-color: white;
    padding: 10px;
    border: 1px solid gray;
}

.energy-popup-fullscreen{
    width: 0px;
    opacity: 0;
    height: 0px;
    pointer-events: none;
}

.energy-popup-fullscreen[show='true']{
    width: 100%;
    opacity: 1;
    height: 100%;
    pointer-events: all;
}

.energy-image{
	width: 0px;
	height: 0px;
}

.energy-image[show='true']{
	width: 100vw;
	height: 100vh;
	object-fit: scale-down;
}
