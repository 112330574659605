.sold-wrapper{
	position: absolute;
	width: 100%;
	height: 30px;
	background-color: #1111117d;
	z-index: 2;
	display: flex;
	align-items: center;
}

.sold-text{
	margin-left: 15px;
	color: white;
	font-family: Gill Sans W01,Arial,sans-serif;
	font-size: 10px;
	letter-spacing: .1em;	
}