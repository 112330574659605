.sell-top{
	background-image: url('./SellTop.svg');
	height: 200px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #f5f1ed;
	display: flex;
	justify-content: center;
}

.sell-top-title{
	color: white;
	text-align: center;
	margin-top: 69px;
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 25px;
}

.sell-middle{
	background-color: #f5f1ed;
	padding: 50px 10vw;
}

.sell-middle-text{
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 18px;
	line-height: 38px;
	text-align: justify;
}

.sell-middle-title{
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 28px;
	margin-top: 50px;
	display: flex;
	justify-content: center;
}

.sell-middle-title-right{
	margin-top: 50px;
	margin-left: 15px;
}

.sell-middle-form{
	margin-top: 50px;
	display: flex;
}

.sell-middle-form-left{
	height: 25vw;

}

.sell-middle-form-right{
	margin-left: 5vw;
	padding-bottom: 40px;
	border-bottom: 1px solid black;
}

.sell-middle-image{
	height: 100%;
}

.sell-middle-form-element{
	margin-bottom: 30px;
}

.sell-middle-form-field{
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 16px;
    margin-bottom: 5px;
}


.sell-middle-form-input{
    height: 35px;
    width: 30vw;
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 17px;
}

.sell-middle-form-submit{
	margin-top: 35px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 250px;
    height: 40px;
    background-color: #000;
    color: white;
    font-family: Gill Sans W01,Arial,sans-serif;
    font-size: 11px;
    letter-spacing: .15em;
    filter: none;
    transition: filter 0.5s;
    cursor: pointer;
    position: relative;
    left: calc(50% - 125px);
}

.sell-middle-form-submit:hover{
	filter: invert(100%);
}

.sell-middle-bottom{
	position: relative;
	margin:auto;
	width: fit-content;
}

.sell-middle-bottom-title{
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 21px;
	margin-top: 100px;
	display: flex;
	justify-content: center;
}


.sell-middle-bottom-call{
	margin-top: 35px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 250px;
    height: 40px;
    background-color: #000;
    color: white;
    font-family: Gill Sans W01,Arial,sans-serif;
    font-size: 13px;
    letter-spacing: .15em;
    filter: none;
    transition: filter 0.5s;
    cursor: pointer;
    position: relative;
    left: calc(50% - 125px);
}

.sell-middle-bottom-call:hover{
	filter: invert(100%);
}

.sell-online-background{
	width: 100%;
	display: flex;
	flex-direction: column;
	background-image: url('./sellonline.png');
	justify-content: center;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
}


.sell-onlinequote-title{
    align-items: center;
    background-color: #111;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    padding-top: 50px;
	color: white;
	font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 20px;
	padding-left: 5px;
	padding-right: 5px;
}

.sell-onlinequote{
	backdrop-filter: blur(3px);
    background-color: #ffffff87;
    margin-top: 100px;
	margin-bottom: 100px;
    border-radius: 5px;
    box-shadow: 0px 3px 10px -5px;
	width: 70vw;
}

.sell-onlinequote-wrapper{
	display: flex;
    justify-content: space-evenly;
	flex-wrap: wrap;
}

.sell-onlinequoteform-wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
	padding-left: 5px;
	padding-right: 5px;
}

.sell-step-wrapper{
	
}

 .sell-online{
	transition: filter 0.5s;   
    display: flex;
    flex-direction: column;
    align-items: center;
 }

 .sell-onlinequote-header{
	font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 20px;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 3;
    padding: 15px;
    color: #353535;
    background-color: #ffffffa8;
	box-shadow: 0px 0px 12px -7px;
    border-radius: 5px 5px 0px 0px;
 }


 .sell-onlinequote-form1{
	letter-spacing: .02em;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 3;
    padding-top: 80px;
    padding-bottom: 25px;
    color: #232323;
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 20px;
	padding-left: 15px;
	padding-right: 15px;
 }

 .sell-onlinequote-button{
    background-image: linear-gradient(to right, #dfdfdf , #f1f1f1);
    height: 100px;
    width: 100px;
    position: relative;
    display: flex;
    align-items: center;
    background-color: white;
    cursor: pointer;
    padding: 20px;
    box-shadow: 0px 2px 15px -6px;
    justify-content: center;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 5px;
	border: 2px solid white;
	transition: border 0.1s;
 }

 .sell-online-image{
	width: 50px;
	position: relative;
	z-index: 2;
	filter: brightness(0) saturate(100%) invert(20%) sepia(0%) saturate(7491%) hue-rotate(78deg) brightness(92%) contrast(90%);
	
 }

 .sell-onlinequote-button:hover > .sell-online{
	filter: invert(50%);
}

.sell-curtain{
	position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    top: 0px;
    left: 0px;
    transition: width 0.5s;
	border-radius: 5px;
}

.sell-onlinequote-button:hover > .sell-curtain{
	width: 0px;
}

.sell-onlinequote-button[active='true'] > .sell-curtain{
	width: 0px;
}

.sell-onlinequote-button[active='true']{
	border: 2px solid #616161;
}

 .sell-button-text{
	font-family: Gill Sans W01,Arial,sans-serif;
    letter-spacing: .1em;
    font-size: 10px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    transform: scale(1,0.89);
    padding-top: 10px;
	color: #353535;
 }

.sell-button-wrapper{
	display: flex;
    margin-top: 40px;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

}

.sell-forward{
	cursor: pointer;
    width: 140px;
    background-color: #3d3c3c8a;
    text-align: center;
    color: #ffffff;
    box-shadow: -2px 1px 12px -10px black;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 13px;
    font-family: Gill Sans W01,Arial,sans-serif;
    letter-spacing: .1em;
    justify-content: space-evenly;
	transition: filter 0.5s;
	margin-top: 15px;
	height: 40px;
	left: 20vw;
    position: relative;
}

.sell-forward:hover{
	filter: contrast(200%);
}

 .arrow{
    font-size: 20px;
    font-family: minion;
	FONT-WEIGHT: 600;
 }

 .arrow2{
    font-size: 15px;
    font-family: minion;
	FONT-WEIGHT: 600;
 }

.sell-back{
	cursor: pointer;
    width: 60px;
    background-color: #3d3c3c8a;
    text-align: center;
    color: #ffffff;
    box-shadow: -2px 1px 12px -10px black;
    display: flex;
    align-items: center;
    flex-direction: row;
    letter-spacing: .1em;
    justify-content: space-evenly;
    transition: filter 0.5s;
    height: 35px;
    position: relative;
	margin-right: 30px;
	margin-left: 30px;
}

.sell-bottom-row{
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
}

.sell-back:hover{
	filter: contrast(200%);
}

.sell-back[step="1"] {
    pointer-events: none;
    background-color: rgb(255, 255, 255);
    filter: blur(1px);
    opacity: 0%;
}

.sell-forward[send="true"]{
    width: 0px;
    height: 0px;
    opacity: 0;
    pointer-events: none;
}

.sell-back[send="true"]{
    width: 0px;
    height: 0px;
    opacity: 0;
    pointer-events: none;
}

.sell-slider{
    width: 100%;
    height: 20px;
	margin: 2vw 2vw 0vw 2vw;
	width: 50%;
	min-width: 180px;
}

.sell-slider-track{
	top: 8px;
	height: 4px;
	background: white;
}

.sell-slider-track.sell-slider-track-0{
	background: #c1c1c1;
}

.sell-slider-thumb{
	cursor: pointer;
	background: #c1c1c1;
	width: 20px;
	height: 20px;
	border-radius: 100%;
	outline: none;
	transition: filter 0.5s;
}

.sell-slider-thumb:hover {
	filter: contrast(150%) invert(10%)
	
}

.sell-empty-bar{
	height: 5px;
    width: 100%;
    background-color: #ffffff96;
    border-radius: 1px;
    margin-bottom: 50px;
    box-shadow: 1px 1px 5px -3px;
}

.sell-full-bar{
	position: absolute;
    height: 5px;
    border-radius: 1px;
	background: linear-gradient(90deg, rgba(187,187,187,1) 0%, rgba(171,171,171,1) 50%, rgba(143,143,143,1) 100%);
    transition: width 0.3s;
}

.sell-bar-wrapper{
	position: relative;
	width: 90%;
	margin-left: 5%;
	margin-right: 5%;
	display: flex;
	justify-content: center;
	padding-top: 30px;
	height: 22px;
}

.sell-onlinequote-service-checks{
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    flex-direction: row;
    cursor: default;
    align-items: center;
    justify-content: center;
}

.sell-onlinequote-check{
	padding: 15px 15px;
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 18px;
    text-align: left;
}

.sell-onlinequote-servicetexts{
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    flex-direction: row;
    cursor: default;
    background-color: #0000008a;
    padding: 15px;
    margin-bottom: 6vw;
}

.sell-onlinequote-text{
    margin: 15px 15px;
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 20px;
    color: white;
    text-align: left;

}

.sell-online-image2{
	display: flex;
    width: 70px;
    position: relative;
    z-index: 2;
    filter: brightness(0) saturate(100%) invert(20%) sepia(0%) saturate(7491%) hue-rotate(78deg) brightness(92%) contrast(90%);
    opacity: 60%;
    padding: 2vw;
}

.sell-step2-wrapper{
    background-color: #ffffffd4;
    border-radius: 5px;
    box-shadow: 0px 3px 10px -5px;
    position: relative;
    top: 2vw;
    display: flex;
    flex-direction: column;
    margin: 0px 60px;
    justify-content: center;
}

.sell-step2{
	display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.sell-onlinequote-form2{
	letter-spacing: .02em;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 3;
    padding-top: 3vw;
    padding-bottom: 5px;
    color: #232323;
    font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 20px;
}


.sell-slider-value{
	display: flex;
	font-size: 15px;
	opacity: 0%;	
}

.sell-slider-minmax{
	display: flex;
    color: #9b9b9b;
    justify-content: space-around;
    width: 100%;
    font-size: 14px;
	margin: 0vw 0vw 2vw 0vw;
}

.sell-slider-big-values{
    display: flex;
    border: 1px solid #c7c7c7;
    width: auto;
    height: 5vw;
    align-items: center;
	margin: 0vw 3vw;
}
.sell-slider-big-value{
    align-items: center;
    position: relative;
	display: flex;
	margin-left: 2vw;
    font-size: 19px;
	flex-basis: 50%;
	width: 50%;
}

.sell-slider-big-text{
	margin-left: 10px;
    position: relative;
    color: #818181;
	flex-basis: 50%;
	width: auto;
	text-align: right;
	margin-right: 1vw;
}

@media only screen and (max-width: 1024px) {
	.sell-top-title{
		font-size: 18px;
	}
	.sell-middle{
		padding-top: 0px;
	}
	.sell-middle-text{
		font-size: 14px;
		line-height: 23px;
	}
	.sell-middle-title{
		font-size: 18px;
	}
	.sell-middle-form{
		flex-direction: column;
		align-items: center;
	}
	.sell-middle-form-left{
		width: 80vw;
		height: auto;
		margin-bottom: 15px;
	}
	.sell-middle-image{
		height: 100%;
		width: 100%;
	}
	.sell-middle-form-input{
		width: 90%;
	}
	.sell-middle-form{
		margin-top: 20px;
	}
	.sell-middle-form-field{
		font-size: 14px;
	}
	.sell-middle-bottom-title{
		font-size: 18px;
		margin-top: 30px;
	}
	.sell-middle-form-right{
		margin-left: 0px;
	}
}

.sell-middle-form-field[rq="true"]{
    color: #bb5353;
}

.sell-middle-form-2{
	margin-top: 50px;
	display: flex;
	opacity: 0;
	font-size: 24px;
}

.sell-middle-form[send="true"]{
	opacity: 0;
	pointer-events: none;
}

.sell-middle-form-2[send="true"]{
	opacity: 1;
}

.autocomplete-wrapper{
    display: flex;
    width: 100%;
    height: fit-content;
}

.autocomplete-map-wrapper{
    width: 250px;
    height: 250px;
}

.opaqueStep{
    opacity: 0;
    height: 0px;
    width: 0px;
    pointer-events: none;
    display: none;
}

.opaqueStep[step="7"]{
    display: block;
    height: auto;
    width: auto;
    opacity: 1;
    pointer-events: all;
}
.opaqueStep[send="true"]{
    opacity: 0;
    height: 0px;
    width: 0px;
    pointer-events: none;
}

.opaqueStep2{
    opacity: 0;
    height: 0px;
    width: 0px;
    pointer-events: none;
}

.opaqueStep2[send="true"]{
    height: auto;
    width: auto;
    opacity: 1;
    pointer-events: all;
}

@media screen and (max-width: 1024px){
	.sell-onlinequote-check{
		font-size: 13px;
		text-align: left;
	}
}

@media screen and (max-width: 746px){
	.mobilenone1{
		display: none;
	}
}

@media screen and (max-width: 600px){
	.sell-onlinequote{
		width: 90vw;
	}
}

@media screen and (max-width: 576px){
	.sell-onlinequote-text{
		font-size: 14px;
	}
	.sell-onlinequote-title{
		font-size: 16px;
	}
	.sell-onlinequote-check{
		font-size: 15px;
	}
    .mobilenone{
        display: none;
    }
	.sell-onlinequote-header{
		font-size: 16px;
	}
	.sell-onlinequote-form1 {
		font-size: 16px;
	}
	.sell-slider-big-value{
		font-size: 16px;
	}
	
}

@media screen and (max-width: 490px){
	.sell-onlinequote-servicetexts{
		flex-direction: column;
	}
	.sell-forward{
		font-size: 12px;
		width: 100px;
	}
}

@media screen and (max-width: 362px){
	.sell-forward{
		font-size: 10px;
		width: 80px;
	}
	.sell-onlinequote{
		width: 98vw;
	}
}


