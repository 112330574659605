.financial-wrapper{
	margin-bottom: 50px;

}

.financial-background{
	width: 100%;
	position: fixed;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-image: url('./financial.png');
	justify-content: center;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}


.financial-top{
	background-image: url('./SellTop.svg');
	height: 200px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	justify-content: center;
	cursor: default;
}

.financial-top-title{
	color: white;
	text-align: center;
	margin-top: 69px;
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 24px;
	cursor: default;
}

.financial-texts{

}

.financial-middle{
	cursor: default;
	padding: 1vw 15vw;
}

.financial-box{
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(1px);
	margin-top: 10px;
	margin-bottom: 10px;
}

.financial-box2{
    display: flex;
    flex-direction: column;
    align-items: center;
	background-color: #d5cfbf5c;
    backdrop-filter: blur(2px);
	margin-bottom: 30px;
	padding-bottom: 1vw;
	padding-top: 1vw;
}

.financial-middle-text-left{
    text-align: justify;
    line-height: 3em;
	margin-right: 20%;
	font-family: Gill Sans W01,Arial,sans-serif;
    letter-spacing: .03em;
	font-size: 16px;
	display: flex;
	font-weight: 600;
    color: #bd857a;
	line-height: 30px;
	padding-top: 10px;
	cursor: default;
}

.financial-middle-text-right{
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 18px;
	line-height: 38px;
	text-align: justify;
	cursor: default;
}

.financial-middle-title{
	font-family: Minion W01,Times,Times New Roman,serif;
    font-size: 23px;
    display: flex;
    align-items: center;
	cursor: default;
    flex-direction: row;
}

.financial-middle-title-1{
	font-family: Gill Sans W01,Arial,sans-serif;
    letter-spacing: .1em;
    font-size: 12px;
    display: flex;
	cursor: default;
    font-weight: 800;
    color: #1a1a1a;
    padding: 15px;
    align-items: center;
}


.financial-middle-text2{
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 18px;
	line-height: 27px;
	cursor: default;
	text-align: justify;
}

.financial-middle-container2{
    padding: 30px;
	cursor: default;
}

.financial-middle-box-wrapper{
	background-color: #dfd7c37d;
	border-radius: 5px;
	cursor: default;
	margin: 0% 7%;
	backdrop-filter: blur(3px);
	box-shadow: 0px 3px 7px -4px;
}

.financial-second-title{
	text-align: center;
    padding-top: 35px;
	font-family: Gill Sans W01,Arial,sans-serif;
    letter-spacing: .1em;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    font-weight: 800;
    color: #1a1a1a;
    line-height: 30px;
    cursor: default;
}

.financial-middle-box{
	display: flex;
	cursor: default;
}

.financial-middle-title-3{
	font-family: Gill Sans W01,Arial,sans-serif;
    letter-spacing: .1em;
	font-size: 13px;
	display: flex;
	flex-direction: column;
	font-weight: 800;
    color: #1a1a1a;
	line-height: 30px;
	cursor: default;

}


.financial-middle-bottom{
	position: relative;
	margin:auto;
	width: fit-content;
	cursor: default;
	padding-bottom: 30px;
	display: flex;
	flex-direction: column;
	align-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.financial-middle-bottom-title{
	font-family: Minion W01,Times,Times New Roman,serif;
	font-size: 20px;
	margin-top: 50px;
	display: flex;
	justify-content: center;
	cursor: default;
	color: white;
}

 .financial-box3{
	width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0000008a;
	margin-top: 50px;
 }

.financial-middle-bottom-call{
	margin-top: 35px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 250px;
    height: 40px;
    background-color: #000;
    color: white;
    font-family: Gill Sans W01,Arial,sans-serif;
    font-size: 13px;
    letter-spacing: .15em;
    filter: none;
    transition: filter 0.5s;
    cursor: pointer;
}

.financial-middle-bottom-call:hover{
	filter: invert(100%);
}

@media only screen and (max-width: 1024px) {
	.financial-top-title{
		font-size: 18px;
	}
	.financial-middle-text-left{
		font-size: 15px;
		line-height: 23px;
		padding-bottom: 5px;
	}
	.financial-middle-text-right{
		font-size: 17px;
		line-height: 23px;
	}
	.financial-middle-title-1{
		font-size: 11px;
	}
	.financial-middle-container2{
	  padding: 15px;
	  padding-bottom: 20px;

	}
	.financial-middle-text2{
	    font-size: 16px;
		text-align: left;
		line-height: 23px;

	}
	.financial-second-title{
		font-size: 12px;
	}
	.financial-middle-title-3{
		font-size: 12px;
		line-height: 21px;
	}
	
	.financial-middle-bottom-title{
		font-size: 17px;
		padding-left: 11vw;
		padding-right: 11vw;
	}
	.sell-middle-bottom-title{
		font-size: 15px;
		margin-top: 30px;
	}
	.sell-middle-form-right{
		margin-left: 0px;
	}

}
@media only screen and (max-width: 700px) {
	.financial-top-title{
		font-size: 16px;
		padding-left: 10px;
		padding-right: 10px;
	}
	.financial-middle{
		padding: 1vw 10vw;
	}
	.financial-middle-text-left{
		font-size: 13px;
		line-height: 20px;
		padding-bottom: 5px;
	}
	.financial-middle-text-right{
		font-size: 15px;
		line-height: 23px;
	}
	.financial-middle-title-1{
		font-size: 10px;
	}
	.financial-middle-container2{
	  padding: 10px;
	  padding-bottom: 20px;

	}
	.financial-middle-text2{
	    font-size: 14px;
		text-align: left;
		line-height: 23px;
		padding: 10px;

	}
	.financial-middle-title{
	
	}
	.financial-second-title{
		font-size: 11px;
	}
	.financial-middle-title-3{
		font-size: 10px;
		line-height: 21px;
		
	}
	.financial-middle-box {
		display: flex;
		cursor: default;
		flex-direction: column;
	}

	.financial-middle-bottom-title{
		font-size: 16px;
		padding-left: 11vw;
		padding-right: 11vw;
	}
	.financial-middle-bottom-call {
      margin-top: 20px;
	  width: 200px;
	  font-size: 11px;
  }
}
@media only screen and (max-width: 400px) {
	.financial-top-title{
		font-size: 14px;
		padding-left: 10px;
		padding-right: 10px;
	}
	.financial-middle{
		padding: 1vw 10vw;
	}
	.financial-middle-text-left{
		font-size: 11px;
		line-height: 16px;
		padding-bottom: 5px;
	}
	.financial-middle-text-right{
		font-size: 12px;
		line-height: 20px;
	}
	.financial-middle-title-1{
		font-size: 9px;
		padding-top: 10px;
		padding-bottom: 5px;
	}
	.financial-middle-container2{
	  padding: 10px;
	  padding-bottom: 0px;

	}
	.financial-middle-text2{
	    font-size: 12px;
		text-align: left;
		line-height: 23px;
		padding: 10px;

	}
	.financial-middle-title{
		flex-direction: column;
	}
	.financial-second-title{
		font-size: 10px;
		padding-top: 10px;
	}
	.financial-middle-title-3{
		font-size: 9px;
		
	}
	.financial-middle-box {
		display: flex;
		cursor: default;
		flex-direction: column;
	}
	.financial-middle-bottom {
		padding-bottom: 15px;
		display: flex;
		flex-direction: column;
		align-content: center;
		align-items: center;
		flex-wrap: wrap;
	}
	.financial-middle-bottom-title{
		font-size: 12px;
		padding-left: 2vw;
		padding-right: 2vw;
		margin-top: 35px;
		line-height: 18px;
		text-align: center;
	}
	.financial-middle-bottom-call {
      margin-top: 15px;
	  width: 200px;
	  font-size: 9px;
  }
}
.sell-middle-form-field[rq="true"]{
    color: #bb5353;
}

.sell-middle-form-2{
	margin-top: 50px;
	display: flex;
	opacity: 0;
	font-size: 24px;
}

.sell-middle-form[send="true"]{
	opacity: 0;
	pointer-events: none;
}

.sell-middle-form-2[send="true"]{
	opacity: 1;
}
