.desktop-language-wrapper{
    display: block;
    position: absolute;
    opacity: 0;
    width: fit-content;
    height: fit-content;
    transition: opacity 0.3s;
    background-color: white;
    padding: 25px;
    pointer-events: none;
    top: 50px;
    cursor: default;
}

.desktop-header-right-element:hover > .desktop-language-wrapper{
    opacity: 1;
    pointer-events: auto;
}

.desktop-language-wrapper:hover{
    opacity: 1;
    pointer-events: auto;
}
